<template>
  <!-- 新 - 首页 - 数字服务 -->
  <div class="digitalServices gotop">
    <headPageNew ref="headPageNew" activeName="3"></headPageNew>
    <div class="banner"></div>
    <div class="main main1">
      <h3>安知教培系统</h3>
      <div class="educationalTraining">
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining1.png" alt="">
          <div>
            <h6>报名招生</h6>
            <p>搭载平台自主研发的裂变海报、分享、秒杀、折扣等运营工具，实现一站式报名+精准引流功能</p>
          </div>
        </div>
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining2.png" alt="">
          <div>
            <h6>多终端学习</h6>
            <p>支持网页、APP、小程序等多终端学习，便捷灵活，满足多样化场景需求，有效的整合碎片时间</p>
          </div>
        </div>
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining3.png" alt="">
          <div>
            <h6>智能题库</h6>
            <p>千余套权威题库资源，提供组卷抽题、分项练习、错题复习与分析等个性化功能，高效巩固学习效果</p>
          </div>
        </div>
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining4.png" alt="">
          <div>
            <h6>云端考试</h6>
            <p>提供全流程考务保障，支持AI防作弊、人脸抓拍、禁止复制、切屏限制等功能，提升在线考试稳定性</p>
          </div>
        </div>
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining5.png" alt="">
          <div>
            <h6>评价管理</h6>
            <p>支持自动阅卷、智能评价、主观题在线评阅、在线问答等功能，提升在线评价效率及精准度</p>
          </div>
        </div>
        <div class="educationalTInner">
          <img src="../../assets/loginIndexNew/educationalTraining6.png" alt="">
          <div>
            <h6>经营分析</h6>
            <p>智能分析运营数据并实时展示，涵盖招生、报名、培训、考试等多个维度，及时掌握运营情况</p>
          </div>
        </div>
      </div>
    </div>
    <div  class="main">
      <h3>安知企培系统</h3>
      <div class="enterpriseTraining">
        <el-radio-group v-model="tabPosition" style="margin-bottom: 40px;">
          <el-radio-button label="1">企培商城</el-radio-button>
          <el-radio-button label="2">培训管理</el-radio-button>
          <el-radio-button label="3">移动学习</el-radio-button>
          <el-radio-button label="4">在线考试</el-radio-button>
        </el-radio-group>
        <div class="enterpriseTInfoBox">
          <div :class="tabPosition == '1'?'enterpriseTInfoBoxInner positionETIBI-1 clearfix':tabPosition == '2'?'enterpriseTInfoBoxInner clearfix positionETIBI-2':tabPosition == '3'?'enterpriseTInfoBoxInner clearfix positionETIBI-3':tabPosition == '4'?'enterpriseTInfoBoxInner clearfix positionETIBI-4':'enterpriseTInfoBoxInner clearfix'">
            <div class="enterpriseTInfo clearfix">
              <div class="animation">
                <div class="shade">
                  <el-button type="primary" round @click="onCourseCenter">全部课程<i class="el-icon-right el-icon--right"></i></el-button>
                </div>
                <ul class="clearfix">
                  <li><img src="../../assets/loginIndexNew/animationbg.png" alt=""></li>
                  <li><img src="../../assets/loginIndexNew/animationbg.png" alt=""></li>
                </ul>
              </div>
            </div>
            <div class="enterpriseTInfo">
              <div class="enterpriseTInfoInner">

                <div class="enterpriseText">
                  <p>
                    <strong>个性化页面：</strong>
                    <span>自定义企业logo、系统图标、页面模块</span>
                  </p>
                  <p>
                    <strong>智能配置课程：</strong>
                    <span>根据公司组织架构及职能快速匹配适合课程</span>
                  </p>
                  <p>
                    <strong>监管后端支持：</strong>
                    <span>支持对培训进度、学习及考试情况、课程资源库等数据，一站式统计及导出</span>
                  </p>
                  <el-button type="primary" round @click="loginShow(false)">免费试用<i class="el-icon-right el-icon--right"></i></el-button>
                </div>
                <img src="../../assets/loginIndexNew/enterpriseTrainingImg1.png" alt="">
              </div>
            </div>
            <div class="enterpriseTInfo">
              <div class="enterpriseTInfoInner">
                <div class="enterpriseText">
<!--                  <p>-->
<!--                    <strong>移动学习：</strong>-->
<!--                    <span>随时学习开展学习</span>-->
<!--                  </p>-->
                  <p>
                    <strong>支持多终端：</strong>
                    <span>实现网页、APP、小程序多终端学习，满足多样化场景需求</span>
                  </p>
                  <p>
                    <strong>多元化内容：</strong>
                    <span>支持视频、图文、文字等多种内容组合，提升员工学习兴趣与效率</span>
                  </p>
                  <p>
                    <strong>更高学习效率：</strong>
                    <span>员工随时参与培训，不受时间地域限制，进度效果实时掌控，提升沉浸式学习体验</span>
                  </p>
                  <el-button type="primary" round @click="loginShow(true)">免费使用<i class="el-icon-right el-icon--right"></i></el-button>
                </div>
                <img src="../../assets/loginIndexNew/enterpriseTrainingImg3.png" alt="">
              </div>
            </div>
            <div class="enterpriseTInfo">
              <div class="enterpriseTInfoInner">
                <div class="enterpriseText">
<!--                  <p>-->
<!--                    <strong>基础功能：</strong>-->
<!--                    <span>多种题型支持、批量导入及编辑、智能组卷、自动阅卷、多种刷题模式等</span>-->
<!--                  </p>-->
                  <p>
                    <strong>防作弊功能：</strong>
                    <span>支持人脸抓拍、禁止复制粘贴、禁止切屏等功能，保障培训过程真实有效</span>
                  </p>
                  <p>
                    <strong>自定义考试：</strong>
                    <span>支持多种题型、批量编辑、智能组卷、答案解析等多样化功能，帮助开发企业独有的考试体系</span>
                  </p>
                  <p>
                    <strong>考试统计：</strong>
                    <span>支持对参与考试的人员、时间、考试情况等数据的统计及导出</span>
                  </p>
<!--                  <el-button type="primary" round @click="loginShow(false)">免费使用<i class="el-icon-right el-icon&#45;&#45;right"></i></el-button>-->
                </div>
                <img src="../../assets/loginIndexNew/enterpriseTrainingImg2.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage />
    <hz/>
  </div>
</template>

<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "@/views/wrongAuthenticatePage/bottomPage";

import hz from "../wrongAuthenticatePage/hz.vue";
export default {
  name: "digitalServices",
  components: {
    headPageNew,
    bottomPage,
    hz
  },
  data() {
    return {
      tabPosition: '1'
    }
  },
  methods:{
    onCourseCenter() {
      // console.log('onCourseCenter')
      this.$router.push({
        path: "/wrongAuthenticatePage/courseCenter",
      });
    },

    // 打开登录
    loginShow(xy){
      this.$refs.headPageNew.loginShow(xy)
    },
  }
}
</script>

<style lang="less" scoped>
.digitalServices{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner2.png");
}
.main{
  position: relative;
  width: 1440px;
  margin: 0 auto;
  h3{
    position: relative;
    text-align: center;
    padding: 40px 0 40px;
    color: #333;
    font-size: 30px;
    font-weight: bold;
  }
}
.main1{
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #FFF;
  }
}

.educationalTraining{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  .educationalTInner{
    position: relative;
    width: 460px;
    margin-right: 30px;
    margin-bottom: 40px;
    &:hover{
      &>div{
        background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
        box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
      }
    }
    &:nth-child(3n){
      margin-right: 0;
    }
    img{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px!important;
      height: 100px!important;
    }
    &>div{
      margin-top: 40px;
      padding: 80px 50px 54px;
      background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #FFFFFF;
      text-align: center;
      transition: 0.5s;
      h6{
        margin: 0;
        font-size: 24px;
        color: #333;
        font-weight: bold;
        margin-bottom: 7px;
      }
      p{
        line-height: 1.4;
        font-size: 16px;
        color: #666;
      }
    }
  }
}
.enterpriseTraining {
  /deep/.el-radio-group{
    display: flex;
    background-color: #FFF;
    border-radius: 8px;
  }
  /deep/.el-radio-button{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  /deep/.el-radio-button__inner{
    font-size: 20px;
    font-weight: bold;
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 25px 0;
    //transition: 0.3s;
    user-select:none;
    background: #FFF;
    //background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
  }
  /deep/.el-radio-button__inner:hover{
    color: #2878ff;
  }
  /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background: #2878FF;
    &:hover{
      color: #fff;
    }
    //background: linear-gradient(180deg, #2878FF 0%, #4678FF 100%);
  }
}

.animation {
  position: relative;
  display: inline-block;
  height: 388px;
  width: 100%;
  overflow: hidden;
  .shade{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
    /deep/.el-button.is-round{
      background: #fff;
      border: 0;
      color: #333;
      transition: 0.5s;
      box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.16);
      font-size: 16px;
      &:hover{
        background: #2878FF;
        color: #fff;
        .el-icon--right{
          color: #fff;
        }
      }
      .el-icon--right{
        color: #2878FF;
      }
    }
  }
  ul{
    width: 3840px;
    animation: run 30s linear infinite;
    li{
      float: left;
      width: 1920px;
      height: 388px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.enterpriseTInfoBox{
  overflow: hidden;
  margin-bottom: 60px;
  .enterpriseTInfoBoxInner{
    width: 5760px;
    height: 388px;
    transition: 0.5s;
    transform: translateX(0);
    &.positionETIBI-1{
      transform: translateX(0);
    }
    &.positionETIBI-2{
      transform: translateX(-25%);
    }
    &.positionETIBI-3{
      transform: translateX(-50%);
    }
    &.positionETIBI-4{
      transform: translateX(-75%);
    }
  }
}

.enterpriseTInfo{
  float: left;
  width: 1440px;
  height: 388px;
  overflow: hidden;
  .enterpriseTInfoInner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 54px 64px 54px 80px;
    background-image: url("../../assets/loginIndexNew/enterpriseTrainingBg.png");
    background-size: cover;
    flex-wrap: wrap;
    img{
      width: 499px!important;
      height: 286px!important;
    }
    .enterpriseText{
      p{
        position: relative;
        line-height: 30px;
        padding-left: 19px;
        font-size: 16px;
        color: #333;
        &:before{
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #FFCB7E;
        }
      }
      /deep/.el-button.is-round{
        background: transparent;
        border: 1px solid #666666;
        color: #666;
        transition: 0.5s;
        font-size: 16px;
        margin-top: 30px;
        &:hover{
          background: #2878FF;
          border: 1px solid #2878FF;
          color: #fff;
          .el-icon--right{
            color: #fff;
          }
        }
        .el-icon--right{
          color: #2878FF;
        }
      }
    }
  }
}

/* 定义动画 */
@keyframes run {
  to {
    transform: translateX(-1920px);
  }
}

@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }
  .educationalTraining .educationalTInner{
    width: 380px;
  }
  .enterpriseTraining{
    /deep/.el-radio-button__inner{
      padding: 20px 0;
    }
  }
  .enterpriseTInfoBox{
    .enterpriseTInfoBoxInner{
      width: 4800px;
    }
  }
  .enterpriseTInfo .enterpriseTInfoInner{
    padding: 54px 45px;
  }
  .enterpriseTInfo{
    width: 1200px;
  }
  .enterpriseTInfo .enterpriseTInfoInner img{
    width: 338px!important;
    height: 193px!important;
  }
}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
</style>